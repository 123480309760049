import React from "react";

function Hero() {
  const text = {
    english: "Hello World",
    chinese: "，我是肖哥",
  };

  return (
    <section
      id="home"
      className="h-screen flex items-center justify-center bg-gray-50"
    >
      <div className="text-center">
        <div className="glass-panel">
          <div className="glass-background"></div>
          <h1 className="text-4xl md:text-6xl font-bold mb-4 relative z-10">
            <span className="animate-character" style={{ "--delay": "0.1s" }}>
              {text.english}
            </span>
            {text.chinese.split("").map((char, index) => (
              <span
                key={index}
                className="animate-character"
                style={{ "--delay": `${(index + text.english.length) * 0.1}s` }}
              >
                {char}
              </span>
            ))}
          </h1>
        </div>
        <p className="text-xl md:text-2xl text-gray-600 mb-8">
          产品经理 / 开发者
        </p>

        {/* 个人图片 */}
        <div className="mt-8">
          <img
            src="/personal-web-photo.jpg"
            alt="个人照片"
            className="w-32 h-32 md:w-48 md:h-48 rounded-full mx-auto object-cover shadow-lg
              hover:scale-105 transition-transform duration-300
              border-4 border-white"
          />
        </div>

        {/* 添加引用 */}
        <div className="mt-8">
          <p className="text-gray-600">
            <span className="text-lg md:text-xl font-serif italic">
              "AIaaS is eating the world!"
            </span>
          </p>
          <p className="text-sm md:text-base text-gray-500 mt-2 italic">
            — Chufan Xiao
          </p>
        </div>
      </div>
    </section>
  );
}

export default Hero;
