import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

function Projects() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // 监听窗口大小变化
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const projects = [
    {
      title: "项目一",
      description:
        "这是一个使用 React 和 Tailwind CSS 构建的个人网站项目，展示了响应式设计和现代化的 UI 组件。",
      image: "/project1.jpg", // 确保在 public 文件夹中有这个图片
      technologies: ["React", "Tailwind CSS", "Framer Motion"],
    },
    {
      title: "项目二",
      description:
        "一个电商平台的后台管理系统，实现了商品管理、订单处理和用户管理等核心功能。",
      image: "/project2.jpg",
      technologies: ["Vue", "Element UI", "Node.js"],
    },
    {
      title: "项目三",
      description: "移动端社交应用，支持即时通讯、动态分享和用户互动等功能。",
      image: "/project3.jpg",
      technologies: ["React Native", "Firebase", "Redux"],
    },
    {
      title: "项目四",
      description: "企业级数据分析平台，提供数据可视化和实时监控功能。",
      image: "/project4.jpg",
      technologies: ["Angular", "D3.js", "TypeScript"],
    },
    {
      title: "项目五",
      description: "在线教育平台，支持课程管理、视频播放和在线测评。",
      image: "/project5.jpg",
      technologies: ["Next.js", "MongoDB", "AWS"],
    },
    {
      title: "项目六",
      description: "AI驱动的智能客服系统，提供自动回复和情感分析功能。",
      image: "/project6.jpg",
      technologies: ["Python", "TensorFlow", "FastAPI"],
    },
  ];

  const projectsPerView = isMobile ? 1 : 3;
  const maxIndex = projects.length - projectsPerView;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      // 每次只移动一个项目
      const nextIndex = prevIndex + 1;
      // 如果到达末尾，回到开始
      return nextIndex > maxIndex ? 0 : nextIndex;
    });
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      // 每次只移动一个项目
      const nextIndex = prevIndex - 1;
      // 如果到达开始，跳到末尾
      return nextIndex < 0 ? maxIndex : nextIndex;
    });
  };

  return (
    <section id="projects" className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">我的项目</h2>

        {/* 项目展示区域 */}
        <div className="relative">
          {/* 左右控制按钮 */}
          <button
            onClick={handlePrev}
            className="absolute left-2 md:-left-4 top-1/2 -translate-y-1/2 z-20
              bg-white/90 hover:bg-white p-2 rounded-full shadow-lg
              transform transition-all duration-200 hover:scale-110
              focus:outline-none"
            aria-label="Previous project"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          <button
            onClick={handleNext}
            className="absolute right-2 md:-right-4 top-1/2 -translate-y-1/2 z-20
              bg-white/90 hover:bg-white p-2 rounded-full shadow-lg
              transform transition-all duration-200 hover:scale-110
              focus:outline-none"
            aria-label="Next project"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>

          {/* 轮播容器 */}
          <div className="overflow-hidden">
            <motion.div
              className="flex"
              style={{
                width: `${projects.length * (100 / projectsPerView)}%`,
              }}
              initial={false}
              animate={{
                x: `${-(currentIndex * (100 / projects.length))}%`,
              }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 30,
              }}
            >
              {projects.map((project, index) => (
                <motion.div
                  key={index}
                  className={`${isMobile ? "w-full" : "w-1/3"} px-4`}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="bg-white rounded-lg shadow-lg overflow-hidden h-full">
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-48 object-cover"
                    />
                    <div className="p-4">
                      <h3 className="text-lg font-semibold mb-2">
                        {project.title}
                      </h3>
                      <p className="text-gray-600 text-sm mb-4">
                        {project.description}
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {project.technologies.map((tech, i) => (
                          <span
                            key={i}
                            className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded"
                          >
                            {tech}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>

          {/* 页面指示器 */}
          <div className="flex justify-center mt-8 space-x-2">
            {Array.from({ length: maxIndex + 1 }).map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full transition-colors duration-200 
                  ${currentIndex === index ? "bg-blue-500" : "bg-gray-300"}`}
                onClick={() => setCurrentIndex(index)}
                aria-label={`Go to project ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
