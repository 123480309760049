import React from "react";

function About() {
  return (
    <section
      id="about"
      className="py-20 bg-gradient-to-br from-gray-50 to-gray-100"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16 items-center">
          {/* 左侧内容 */}
          <div className="col-span-7 space-y-8">
            <div className="relative">
              <h2 className="text-4xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-500">
                关于我
              </h2>
              <div className="h-1 w-20 bg-gradient-to-r from-blue-600 to-cyan-500 rounded-full mb-8"></div>
            </div>

            <div className="prose prose-lg">
              <p className="text-gray-600 leading-relaxed">
                你好！我是一名充满激情的产品经理和开发者。在过去的10年里，我专注于将创新想法转化为实际产品，并致力于创造卓越的用户体验。
              </p>
            </div>

            {/* 技能卡片区域 */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
              {/* 产品经理技能卡片 */}
              <div className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-100">
                <h3 className="text-xl font-semibold mb-4 text-blue-600">
                  产品经理技能
                </h3>
                <ul className="space-y-3">
                  {[
                    "产品战略规划与路线图制定",
                    "用户研究与需求分析",
                    "跨团队协作与项目管理",
                    "数据驱动的决策制定",
                  ].map((skill, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                      <span className="text-gray-600">{skill}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* 开发者技能卡片 */}
              <div className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-100">
                <h3 className="text-xl font-semibold mb-4 text-cyan-600">
                  开发者技能
                </h3>
                <ul className="space-y-3">
                  {[
                    "前端开发（React.js生态系统）",
                    "用户界面设计与实现",
                    "性能优化与最佳实践",
                    "新技术探索与应用",
                  ].map((skill, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-cyan-500 rounded-full"></span>
                      <span className="text-gray-600">{skill}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* 右侧个人简介 */}
          <div className="col-span-5 mt-10 lg:mt-0">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-2xl transform rotate-2"></div>
              <div className="relative bg-white rounded-2xl p-8 shadow-lg transform -rotate-1 hover:rotate-0 transition-transform duration-300">
                <h3 className="text-2xl font-semibold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-500">
                  个人简介
                </h3>
                <div className="space-y-4 text-gray-600 leading-relaxed">
                  <p>
                    我是一名产品经理和开发者，专注于将创新想法转化为实际产品，并致力于创造卓越的用户体验。
                  </p>
                  <p>
                    作为产品经理，我擅长产品战略规划与路线图制定、用户研究与需求分析、跨团队协作与项目管理、数据驱动的决策制定。
                  </p>
                  <p>
                    作为开发者，我热衷于前端开发（React.js生态系统）、用户界面设计与实现、性能优化与最佳实践、新技术探索与应用。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
