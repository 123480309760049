import React, { useState } from "react";

function Contact() {
  const [showQRCode, setShowQRCode] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // 这里添加表单提交逻辑
    console.log("Form submitted:", formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <section id="contact" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">联系我</h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* 联系信息 */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h3 className="text-xl font-semibold mb-6">联系方式</h3>
            <div className="space-y-4">
              <div className="flex items-center">
                <svg
                  className="w-6 h-6 text-blue-600 mr-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span>your.email@example.com</span>
              </div>
              <div className="flex items-center">
                <svg
                  className="w-6 h-6 text-blue-600 mr-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span>+86 xxx xxxx xxxx</span>
              </div>
              <div className="flex items-center relative">
                <svg
                  className="w-6 h-6 text-blue-600 mr-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
                </svg>
                <div className="relative inline-block">
                  <button
                    onMouseEnter={() => setShowQRCode(true)}
                    onMouseLeave={() => setShowQRCode(false)}
                    className="hover:text-blue-600 transition-colors"
                  >
                    添加微信
                  </button>

                  {/* 二维码弹出框 */}
                  {showQRCode && (
                    <div
                      className="absolute left-full ml-4 top-1/2 transform -translate-y-1/2 
                      bg-white p-4 rounded-lg shadow-lg z-50 w-40 h-40"
                    >
                      {/* 占位图片，请替换为实际的二维码图片 */}
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <img
                          src="/wechat-qr-code.png"
                          alt="微信二维码"
                          className="w-full h-full object-contain"
                        />
                      </div>
                      {/* 小箭头 */}
                      <div
                        className="absolute -left-2 top-1/2 transform -translate-y-1/2 
                        border-solid border-8 border-transparent border-r-white"
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
