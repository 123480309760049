import React, { useState } from "react";

function Skills() {
  const [likes, setLikes] = useState({});
  const [animatingCategory, setAnimatingCategory] = useState(null);
  const [completedCategories, setCompletedCategories] = useState({});

  const skills = {
    产品技能: [
      { name: "产品规划", level: 90 },
      { name: "用户研究", level: 85 },
      { name: "需求分析", level: 90 },
      { name: "原型设计", level: 85 },
      { name: "数据分析", level: 80 },
    ],
    技术技能: [
      { name: "React", level: 85 },
      { name: "Node.js", level: 85 },
      { name: "Java", level: 90 },
      { name: "Python", level: 75 },
      { name: "Git", level: 80 },
    ],
    软技能: [
      { name: "团队协作", level: 90 },
      { name: "项目管理", level: 85 },
      { name: "沟通能力", level: 90 },
      { name: "问题解决", level: 85 },
    ],
  };

  const handleLike = (category) => {
    if (completedCategories[category]) {
      setLikes((prev) => ({
        ...prev,
        [category]: false,
      }));
      setAnimatingCategory(null);
      setCompletedCategories((prev) => ({
        ...prev,
        [category]: false,
      }));
      return;
    }

    setLikes((prev) => ({
      ...prev,
      [category]: true,
    }));
    setAnimatingCategory(category);

    setTimeout(() => {
      setAnimatingCategory(null);
      setCompletedCategories((prev) => ({
        ...prev,
        [category]: true,
      }));
    }, 1000);
  };

  return (
    <section id="skills" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">专业技能</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {Object.entries(skills).map(([category, skillList]) => (
            <div key={category} className="bg-white p-6 rounded-lg shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">{category}</h3>
                <div className="flex items-center space-x-2 group">
                  <span className="text-sm text-gray-500 group-hover:text-blue-500 transition-colors duration-200">
                    为我助力
                  </span>
                  <div className="relative w-6 h-6 flex items-center justify-center">
                    <button
                      onClick={() => handleLike(category)}
                      className={`absolute transform transition-all duration-300 focus:outline-none
                        ${
                          animatingCategory === category
                            ? "scale-[3] origin-center"
                            : ""
                        } 
                        ${
                          completedCategories[category]
                            ? "text-blue-500"
                            : "text-gray-400"
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill={
                          animatingCategory === category
                            ? "white"
                            : completedCategories[category]
                            ? "currentColor"
                            : "none"
                        }
                        viewBox="0 0 24 24"
                        stroke={
                          animatingCategory === category
                            ? "#374151"
                            : "currentColor"
                        }
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                {skillList.map((skill, index) => (
                  <div key={index}>
                    <div className="flex justify-between mb-1">
                      <span className="text-gray-700">{skill.name}</span>
                      <span className="text-gray-500">
                        {animatingCategory === category ||
                        completedCategories[category]
                          ? "100"
                          : skill.level}
                        %
                      </span>
                    </div>
                    <div className="relative w-full bg-gray-200 rounded-full h-2 overflow-visible">
                      <div
                        className={`h-2 rounded-full transition-all relative
                          ${
                            animatingCategory === category ||
                            completedCategories[category]
                              ? "charging-gradient"
                              : "bg-blue-600"
                          }`}
                        style={{
                          width:
                            animatingCategory === category ||
                            completedCategories[category]
                              ? "100%"
                              : `${skill.level}%`,
                          transition: "width 1s ease-out",
                        }}
                      >
                        {(animatingCategory === category ||
                          completedCategories[category]) && (
                          <div className="absolute -right-2 -top-2 animate-flame text-lg">
                            🔥
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
